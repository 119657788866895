let defaults = {
    baseURL: '',
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
    },
    credentials: 'same-origin',
};

let onError;

const setErrorHandler = (cb) => {
    onError = cb;
};

const getClearBaseUrl = () => {
    if (!defaults.baseURL.endsWith('/')) {
        defaults.baseURL += '/';
    }
    return defaults.baseURL;
};

const baseFetch = async (endpoint, customConfig) => {
    if (!endpoint) {
        return;
    }

    if (endpoint.startsWith('/')) {
        endpoint = endpoint.substring(1);
    }

    const config = {
        ...customConfig,
        ...defaults,
        headers: {
            ...customConfig.headers,
            ...defaults.headers,
        },
    };

    // Add X-XSRF-TOKEN header if cookie is set :
    const cookie = document.cookie.split(';').find((cookie) => cookie.trim().startsWith('XSRF-TOKEN='));
    if (cookie) {
        // url decode the cookie :
        const decodedCookie = decodeURIComponent(cookie.split('=')[1]);
        config.headers['X-XSRF-TOKEN'] = decodedCookie;
    }

    if (!endpoint.startsWith('http://') && !endpoint.startsWith('https://')) {
        endpoint = `${getClearBaseUrl()}${endpoint}`;
    }

    try {
        return window.fetch(endpoint, config).then(async (response) => {
            let responseText;
            if (response.ok) {
                try {
                    responseText = await response.text();
                    return JSON.parse(responseText);
                } catch (e) {
                    return responseText;
                }
            } else {
                let errorMessage = await response.text();
                try {
                    errorMessage = JSON.parse(errorMessage);
                } catch (e) { }
                if (onError) {
                    onError({
                        response: {
                            data: errorMessage,
                            status: response.status,
                        },
                    });
                }
                return Promise.reject({
                    response: {
                        data: errorMessage,
                        status: response.status,
                    },
                });
            }
        });
    } catch (e) {
        if (onError) {
            onError(e);
        }
    }
};

const get = async (endpoint, customConfig) => {
    return await baseFetch(endpoint, {
        method: 'GET',
        ...customConfig,
    });
};

const post = async (endpoint, data = undefined, options = {}) => {
    let config = {
        method: 'POST',
    };
    config.headers = { 'Content-Type': 'application/json', ...options.headers };
    if (data) {
        if (data instanceof FormData) {
            config.body = data;
            delete config.headers['Content-Type'];
        } else {
            config.body = JSON.stringify(data);
        }
    }
    return await baseFetch(endpoint, config);
};

const put = async (endpoint, data = undefined) => {
    let config = {
        method: 'PUT',
    };
    if (data) {
        config.body = JSON.stringify(data);
        config.headers = { 'Content-Type': 'application/json' };
    }
    return await baseFetch(endpoint, config);
};

const del = async (endpoint, customConfig) => {
    return await baseFetch(endpoint, {
        method: 'DELETE',
        ...customConfig,
    });
};

const fetcher = {
    get,
    post,
    put,
    delete: del,
    defaults,
    setErrorHandler,
};

export default fetcher;
