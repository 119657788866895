import { router } from "@inertiajs/vue3"
import { useToast } from "vue-toastification"
import "vue-toastification/dist/index.css"

export const useSaveLastRoute = () => {
    router.on('before', () => {
        // Save the current location to the local storage
        localStorage.setItem('lastVisitedRoute', window.location)
    })
}
